import React, { useEffect } from "react";
import L from "leaflet";
import "./Legend.css";

const Legend = ({ map }) => {
  useEffect(() => {
    if (map) {
      const legend = L.control({ position: "bottomleft" });

      legend.onAdd = () => {
        const div = L.DomUtil.create("div", "info legend");
        div.innerHTML =
          '<div class="info legend leaflet-control"><i style="background:#FFA500;opacity:0.5"></i> Civic<br><i style="background:#FFFF00;opacity:0.5"></i> Commercial<br><i style="background:#00FF00;opacity:0.5"></i> Mixed<br><i style="background:#0000FF;opacity:0.5"></i> Residential<br></div>'
        return div;
      };

      legend.addTo(map);
    }
  }, [map]);
  return null;
}

export default Legend;
