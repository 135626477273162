import React, {useState} from 'react'
import { MapContainer, TileLayer, Popup, CircleMarker, LayersControl } from "react-leaflet"
import 'leaflet/dist/leaflet.css'
import * as styles from '../../assets/css/map.module.css'
import Legend from "./Legend";

const LeafletMap = ({ structures }) => {
  const [map, setMap] = useState(null);
  if (typeof window !== 'undefined') {
    const { BaseLayer } = LayersControl
    return (
      <MapContainer
        center={[30.3210037,-86.1384564]}
        zoom={17}
        style={{ 
          height: 'calc(100vh - 200px)', 
          minHeight: '400px',
          width: '100%', 
          overflowX: 'hidden'
        }}
        whenCreated={setMap}
      >
        <LayersControl>
          <BaseLayer checked name='Streets'>
            <TileLayer
              attribution='Tiles © Esri — Esri, DeLorme, NAVTEQ, TomTom, Intermap, iPC, USGS, FAO, NPS, NRCAN, GeoBase, Kadaster NL, Ordnance Survey, Esri Japan, METI, Esri China (Hong Kong), and the GIS User Community'
              url="//server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}"
            />
          </BaseLayer>
          <BaseLayer name='Imagery'>
            <TileLayer
              attribution='Tiles © Esri — Source: Esri, DeLorme, NAVTEQ, USGS, Intermap, iPC, NRCAN, Esri Japan, METI, Esri China (Hong Kong), Esri (Thailand), TomTom, 2012'
              url="//server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
            />
          </BaseLayer>
        </LayersControl>
          {
            structures.map((structure, i) => {
              if(structure.lat && structure.long) {
                let markerColor
                switch(structure.Building_Type) {
                  case 'Civic':
                    markerColor = '#ffa502'
                    break
                  case 'Mixed':
                    markerColor = '#00fd03'
                    break
                  case 'Commercial':
                    markerColor = '#ffff01'
                    break
                  case 'Residential':
                  default:
                    markerColor = '#0000ff'
                }
              return (
                <CircleMarker 
                  center={[parseFloat(structure.lat), parseFloat(structure.long)]} 
                  radius={5}
                  fillColor={markerColor}
                  fillOpacity={1.0}
                  weight={0}
                  key={i}
                >
                  <Popup>
                    <b>{structure.Structure_Name_1}</b><br/>
                    <hr/>
                    <b>Address: </b>{structure.Address}<br/>
                    <b>Architect: </b>{structure.Architect_1}<br/>
                    <a href={`/structures/${structure.SeasideTwo_ID}`} target='_parent'>More Info</a>
                  </Popup>
                </CircleMarker>
              )
            } else {
              console.log(`Invalid coordinates for ${structure.SeasideTwo_ID}`)
              console.log(structure)
              return null
            }
          })
        }
        <Legend map={map} />
      </MapContainer>
    )
  }
  return null
}

export default LeafletMap