import React from 'react'
import { graphql } from "gatsby"
import Layout from '../components/Layout'
import LeafletMap from '../components/LeafletMap'
const Map = ({ data }) => {
  const structures =  data.allMapCsv.nodes
  return (
    <Layout map="y">
      <LeafletMap structures={structures} />
    </Layout>
  )
}

export default Map

// Data comes from /content/map/map.csv
// Update shared Google Doc at https://docs.google.com/spreadsheets/d/1IHBm2UDZNQ91ilOquYVVOd2Ol7rs61uMKkkkpCKrnwc/edit#gid=333803365
// Choose File > Download > Comma Separated Values (CSV) option.
// Rename the file map.csv and replace existing file.
export const query = graphql`
  {
    allMapCsv {
      nodes {
        Address
        Architect_1
        Building_Type
        lat
        long
        SeasideTwo_ID
        Structure_Name_1
      }
    }
  }`